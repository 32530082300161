import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "declarative-html5-canvas-with-konva-and-react-konva"
    }}>{`Declarative HTML5 Canvas with Konva and react-konva`}</h1>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "https://konvajs.github.io"
      }}>{`Konva`}</a>{` and react-konva. All the benefits of
declarative code, but rendered on the canvas.`}</p>
    <p>{`I'm gonna let Anton Lavrenov, the author of Konva, explain:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Konva is an HTML5 Canvas JavaScript framework that enables high performance
animations, transitions, node nesting, layering, filtering, caching, event
handling for desktop and mobile applications, and much more.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`You can draw things onto the stage, add event listeners to them, move them,
scale them, and rotate them independently from other shapes to support high
performance animations, even if your application uses thousands of shapes.
Served hot with a side of awesomeness.`}</p>
    </blockquote>
    <p>{`That.`}</p>
    <p>{`It's exactly what we need to push our animated React apps to thousands of
elements without spending too much time thinking about the `}<em parentName="p">{`how`}</em>{` of rendering.
Best leave the hairy details to somebody else.`}</p>
    <p>{`Let's try out two examples:`}</p>
    <ol>
      <li parentName="ol">{`Pushing our particle generator to 20,000 elements`}</li>
      <li parentName="ol">{`An n-body collision simulator built with MobX`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      